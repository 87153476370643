<template>
        <section class="mb-10 solutions-holder">   
            <v-layout justify-space-around justify-center>
                <v-flex xs9 sm9 offset-sm1 class="mt-10">
                        <v-card flat tile color="" width="100%" class="transparent">
                             <v-card-title primary-title class="justify-center mb-6 px-0">
                                    <h3 v-if="!is_screen_small" class="zamu-heading transact-heading  slogan-heading mb-3">Enter a World of Limitless Possibilities </h3><br />
                                    <h3 v-if="is_screen_small" class="zamu-heading transact-heading  slogan-heading mb-3">Enter a World of <br />Limitless Possibilities </h3><br />

                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center transact-underline ">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                      
                          <v-row v-for="(section, index) in sectionInfo" :key="index" :class="{'reverse-div': section.reverse}" >
                                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="pull-right  py-0" >
                                       <v-card color="transparent" flat tile height="100%" align="end" class="content-image-holder  py-0" >
                                           <v-card-text align="left" justify="start" class="py-0">
                                           <v-img :src="require(`../../../../assets/images/${section.image}`)" class="content-image" :class="section.imgClass"></v-img>
                                           </v-card-text>

                                       </v-card>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="pull-left" >

                                         <v-row v-for="(content, i) in section.content" :key="i" class="mb-10">
                                          
                                            <v-col cols="12" lg="10" md="10" sm="12" xs="12">
                                                <v-card flat tile color="" width="90%" class="transparent main-content-products">
                                                        <v-card-text >
                                                            <span v-html="content.text"></span>
                                                        </v-card-text>
                                                    </v-card>
                                             
                                            </v-col>
                                        </v-row>
                                    </v-col>
                               
                            </v-row>
                      </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'Slogan',
    mixins: [colorMixin, coreMixin],
    computed: {

        sectionInfo () {
            const data = [
                {
                    section: 1,
                    reverse: true,
                    image: 'Zamu 6.jpg',
                    imgClass: 'secure',
                    content: [
                        {
                            number: '01',
                            header : 'Stable API:',
                            text: `
                            <p>We understand your challenges and we believe that through Zamupay, you can worry less about the risks associated with handling money and focus more on your innovations, growing your sales pipeline and expanding your territories.<p>

                            <p>
                            This digital payments platform is created to solve payment-processing problems that enable International Money Transfer Companies, Corporates SMEs and Merchants to make online and offline payments.
                            </p>
                            `
                        }
                    ]
                },
                {
                    section: 2,
                    reverse: false,
                    image: 'Zamu.jpg',
                    imgClass: 'pay',
                    content: [
                        {
                            number: '03',
                            header : 'Fast and Easy:',
                            text: `
                            <p> It is an innovative product geared towards supporting and automating online payments for institutions of all sizes. It is a fraud-proof, one-stop shop gateway to multiple markets offering a range of services enabling a diverse client range to scale their businesses to new operational levels.</p>
                            `
                        }
                    ]
                }
            ];
            return data;
        },

    }
   

}
</script>

<style>
.solutions-holder {
    background-image: url('https://pilongtermbackups.s3.amazonaws.com/Zamupay/images/rabbitWatermark.png') !important;
    background-position: right center !important;
     background-size: 39%;

}
.header {
    font-size: 20px !important;
}


.secure {
  width: 80%;
}
</style>